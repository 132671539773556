<template>
  <div class="table-main">
    <v-data-table
      :headers="headers"
      :items="points"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-0"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.ident="{ item }">
        <v-btn
          text
          color="primary"
          @click="onSelectPoint(item.ident.ID)"
        >
          {{ item.ident.Name }}
        </v-btn>
      </template>
    </v-data-table>
    <div class="text-center" v-if="totalPage > 1">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5"
        @input="handleGetData"
      />
    </div>
  </div>
</template>
<script>
import apiDishOrders from '@/services/apiDishOrders'
export default {
  props: {
    onSelectPoint: {
      type: Function,
      required: true,
    },
    forceUpdate: {
      type: Date,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    points: [],
    count: null,
    itemsPerPage: 10,
    totalPage: null,
    page: 1,
  }),
  computed: {
    workspaceID () {
      return this.$store.state.app.dtouchWorkspace
    },
    headers() {
      return [
      {
        text: this.$t('dtouch.dishOrders.points.name', this.locale),
        align: 'start',
        sortable: false,
        value: 'ident',
      }, {
        text: 'URL',
        value: 'url',
      }]
    },
    startIndex () {
      return (this.page -1) * this.itemsPerPage
    },
    isDevelopment () {
      return process.env.VUE_APP_ENV === 'development'
    },
  },
  watch: {
    forceUpdate () {
      this.page = 1
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      apiDishOrders.getAllPoints(this.startIndex, this.itemsPerPage) 
        .then((response) => {
          this.points = response.data.map(item => {
            const url = this.isDevelopment 
                ? `http://${item.Domain}.dtouch.local:${window.location.port === '8080' ? '8081' : '8080'}/#/?point=${item.ID}` 
                : `https://${item.Domain}.dtouch.es/#/?point=${item.ID}`
            return {
              ident: {
                ID: item.ID,
                Name: item.Name,
              },
              url,
            }
          })
          this.count = response.count
          this.totalPage = Math.ceil(this.count / this.itemsPerPage)
        })
    },
  },
}
</script>
<style scoped>
.table-main {
  background-color: white;
  padding-bottom: 10px;
}
.table-main .v-data-table {
  border-radius: 0 !important;
}
</style>

